<template>
	<b-row no-gutters>
		<b-col sm="6" md="4" lg="4" xl="2" offset-sm="3" offset-md="4" offset-lg="4" offset-xl="5">
			<!-- <b-jumbotron bg-variant="white"> -->
			<!-- Order Success Image -->
			<b-img :src="imgSrc" fluid-grow class="mt-2 pt-2" />

			<!-- Order Success Text -->
			<div class="text-center font-weight-bold">
				<div>Order Placed</div>
				<div>Your order number is</div>
				<h3 class="font-weight-bold">#{{ orderId }}</h3>
			</div>

			<div class="text-center mt-5">
				<b-alert v-if="displayContactMerchantAlert" show>
					Contacting Store using Whatsapp about the order in
					<span class="text-bold">{{ countDownTime }}</span
					>...
				</b-alert>

				<b-button @click="whatsappContactMerchant" class="whatsapp-button" pill block>
					<!-- Whatsapp Logo -->
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
						<path
							d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
						/>
					</svg>
					Whatsapp order to store
				</b-button>

				<b-button @click="redirectToOrdersPage" variant="primary" class="text-white" pill block>
					Go to Orders
				</b-button>
			</div>
			<!-- </b-jumbotron> -->
		</b-col>
	</b-row>
</template>

<script>
import orderSuccessTransparent from "@/assets/imgs/order-success.jpg";
export default {
	props: ["store"],
	created() {
		if (!this.orderId) {
			// redirect to order's page
			this.redirectToOrdersPage();
			return;
		}
		const { autoWhatsappOrder } = this.store;
		if (autoWhatsappOrder) {
			this.autoWhatsappOrder = autoWhatsappOrder;
			this.initAutoWhatsappOrder();
		}
	},
	destroyed() {
		this.clearTimers();
	},
	data() {
		return {
			imgSrc: orderSuccessTransparent,
			orderId: this.$route.query.orderId,

			// to auto contact store about order
			autoWhatsappOrder: false,
			autoWhatsappOrderTimer: null,
			countDownTime: 3,
			countDownTimer: null,
		};
	},
	computed: {
		displayContactMerchantAlert() {
			if (!this.autoWhatsappOrder) return false;
			if (this.countDownTime <= 0) return false;
			return true;
		},
	},
	methods: {
		initAutoWhatsappOrder() {
			// setting countdown timers
			this.countDownTimer = setInterval(() => {
				this.countDownTime--;
			}, 1000);

			this.autoWhatsappOrderTimer = setTimeout(() => {
				this.clearTimers();
				this.whatsappContactMerchant();
			}, 3000);
		},

		redirectToOrdersPage() {
			this.clearTimers();
			this.$router.replace({ name: "StoreOrders" });
		},
		whatsappContactMerchant() {
			const linkToOrder = this.$options.filters.merchantUrl("/postLogin/merchant/orders/detail?orderId=" + this.orderId);
			const msg = `Hi,\nI just placed an order at your store, Order #${this.orderId}.\n\n${linkToOrder}`;
			const mobile = this.$options.filters.attachCountryCode(this.store.storeMobile);

			window.location.href = "https://wa.me/" + mobile + "?text=" + encodeURIComponent(msg);
		},
		clearTimers() {
			if (this.countDownTimer) clearInterval(this.countDownTimer);
			if (this.autoWhatsappOrderTimer) clearTimeout(this.autoWhatsappOrderTimer);
			this.countDownTimer = null;
			this.autoWhatsappOrderTimer = null;
		},
	},
};
</script>

<style scoped>
.bi-whatsapp {
	margin-top: -4px;
}
</style>
